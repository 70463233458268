
/***

====================================================================
    Gallery Sections
====================================================================

***/

.gallery-section {
  position: relative;
  padding: 100px 0px 70px;
}

.gallery-section .filters {
  position: relative;
  text-align: center;
  margin-bottom: 40px;
}

.gallery-section .filters li {
  position: relative;
  display: inline-block;
  padding: 5px 20px;
  line-height: 24px;
  color: #777777;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  margin: 0px 5px 10px;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.gallery-section .filters li .txt {
  position: relative;
  display: block;
  z-index: 1;
}

.gallery-section .filters li:after {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 1;
  border: 1px solid #f0f0f0;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.gallery-section .filters li:before {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: #4bc700;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.gallery-section .filters li:hover,
.gallery-section .filters li.active {
  color: #ffffff;
}

.gallery-section .filters li:hover:before,
.gallery-section .filters li.active:before {
  opacity: 1;
}

.gallery-section .filters li:hover:after,
.gallery-section .filters li.active:after {
  opacity: 0;
}

.default-portfolio-item {
  position: relative;
}

.gallery-three-column .default-portfolio-item {
  margin-bottom: 30px;
}

.fullwidth-gallery {
  overflow: hidden;
}

.fullwidth-gallery .default-portfolio-item.mix {
  padding: 0px 0px;
  margin-bottom: 1px;
}

.default-portfolio-item.mix {
  display: none;
}

.gallery-section.fullwidth-gallery .items-container {
  margin-left: -1px;
  margin-right: -1px;
}

.fullwidth .default-portfolio-item {
  padding: 0px !important;
}

.sortable-masonry .items-container {
  margin-left: -15px;
  margin-right: -15px;
}

.sortable-masonry .masonry-item {
  margin-bottom: 30px;
  padding: 0px 15px;
}

.sortable-masonry.style-two .masonry-item {
  margin-bottom: 15px;
}

.default-portfolio-item .inner-box {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.default-portfolio-item .image-box {
  position: relative;
  display: block;
}

.default-portfolio-item .image-box img {
  position: relative;
  display: block;
  width: 100%;
}

.default-portfolio-item .overlay-box {
  position: absolute;
  left: 0px;
  top: -100%;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #ffffff;
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  -moz-transform: scaleY(0);
  transform: scaleY(0);
}

.default-portfolio-item .inner-box:hover .overlay-box {
  top: 0px;
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  -moz-transform: scaleY(1);
  transform: scaleY(1);
}

.default-portfolio-item .overlay-inner {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: table;
  vertical-align: middle;
  padding: 10px 30px;
  background: rgba(37, 61, 74, 0.50);
}

.default-portfolio-item .overlay-inner .content {
  position: relative;
  display: table-cell;
  vertical-align: middle;
}

.default-portfolio-item .overlay-inner{
  h3, .h3 {
    font-size: 18px;
    color: #ffffff;
    font-weight: 500;
    text-transform: capitalize;
    a {
      color: #ffffff;
    }
    a:hover {
      color: #f1f1f1;
    }
  }
}


.default-portfolio-item .image-link {
  position: absolute;
  left: -100px;
  bottom: 0px;
  width: 40px;
  height: 40px;
  color: #ffffff;
  line-height: 40px;
  background: #4bc700;
  display: block;
  font-size: 16px;
  z-index: 1;
  text-align: center;
}

.default-portfolio-item:hover .image-link {
  left: 0px;
  transition: all 500ms ease 500ms;
  -webkit-transition: all 500ms ease 500ms;
  -ms-transition: all 500ms ease 500ms;
  -o-transition: all 500ms ease 500ms;
  -moz-transition: all 500ms ease 500ms;
}

