
/*======== property-highlight Styles ===========*/

.property-feature {
  position: relative;
  padding: 120px 0 100px;
}

.property-feature .sec-title {
  margin-bottom: 57px;
}

.property-feature .property-highlight-text {
  padding-top: 60px;
  padding-left: 70px;
}

.property-feature .single-property-highlight {
  margin-bottom: 30px;
}

.property-feature .single-property-highlight h3 {
  color: #1c1c1c;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 13px;
  font-family: 'Raleway', sans-serif;
  font-style: italic;
  text-transform: capitalize;
}

.property-feature .single-property-highlight h3 span {
  color: #4bc700;
  font-size: 22px;
}

.property-feature .single-property-highlight p {
  color: #8a8a8a;
  margin-bottom: 0;
}

.property-feature {

  ul {
    list-style: disc;
    margin: 0 0 0 15px;
  }

  li {
    list-style: disc;
  }
}
