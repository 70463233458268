
/***

====================================================================
    Properties Section style
====================================================================

***/

.sec-title {
  position: relative;
  margin: 0 auto 50px;
  text-align: center;
}

.sec-title.style-2 {
  text-align: left;
}

.sec-title {
  h2, .h2 {
    position: relative;
    font-size: 40px;
    font-weight: 800;
    font-family: 'Raleway', sans-serif;
    line-height: 1.4em;
    margin-bottom: 5px;
    text-transform: uppercase;
    color: #1c1c1c;

    span {
      font-weight: 400;
    }
    strong {
      font-family: 'Raleway', sans-serif;
      color: #4bc700;
      font-weight: 900;
    }

    .light {
      font-family: 'Raleway', sans-serif;
      color: #4bc700;
      font-weight: 400;
    }
  }

}


.sec-title .text {
  color: #6f6f6f;
  font-size: 16px;
}

.sec-title .separator {
  position: relative;
  width: 100%;
  height: 20px;
}

.small-separator {
  margin-bottom: 23px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../images/icons/title-separator.png);
}

.style-2 .small-separator {
  background-image: url(../images/icons/title-separator2.png);
  background-position: left center;
}

.properties-section {
  position: relative;
  background: #ffffff;
  padding: 112px 0px 77px;
}

.properties-section .sec-title {
  margin-bottom: 100px;
}

.three-col-theme {
  position: relative;
}

.three-col-theme .column {
  position: relative;
  margin-bottom: 30px;
}

.three-col-theme .column .inner-box {
  position: relative;
  padding-left: 70px;
}

.three-col-theme .column .inner-box .icon-left {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 50px;
}

.three-col-theme .column .inner-box .icon-left .icon {
  position: relative;
  display: block;
  color: #4bc700;
  line-height: 50px;
  font-size: 48px;
  margin-bottom: 20px;
}

.three-col-theme .column .inner-box h3 {
  color: #253d4a;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.three-col-theme .column .inner-box h3 .number {
  position: relative;
  font-size: 30px;
  font-weight: 700;
}

.five-col-theme {
  position: relative;
}

.five-col-theme .column {
  position: relative;
  padding: 0 15px;
  float: left;
  width: 20%;
  margin-bottom: 30px;
  text-align: center;
}

.five-col-theme .column .inner-box {
  position: relative;
}

.five-col-theme .column .inner-box .icon:before {
  position: absolute;
  content: '';
  color: #fff;
  border: 1px solid #e7e7e7;
  height: 112px;
  width: 116px;
  top: -29px;
  left: 49px;
  transition: .5s ease;
}

.five-col-theme .column .inner-box:hover .icon:after {
  border: none;background-color: transparent;
}
.five-col-theme .column .inner-box:hover .icon:before {
  border: 1px solid #4bc700;
  background: #4bc700;
}

.five-col-theme .column .inner-box .icon:after {
  position: absolute;
  content: '';
  color: #fff;
  border: 1px solid #fff;
  height: 100px;
  width: 100px;
  top: -23px;
  transform: rotate(45deg);
  left: 57px;
  background: #fff;
  transition: all .3s ease;
}

.five-col-theme .column .inner-box:hover .icon span:before {color: #fff;}
.five-col-theme .column .inner-box .icon span:before {
  position: relative;
  display: block;
  color: #4bc700;
  line-height: 50px;
  font-size: 60px;
  margin-bottom: 10px;
  z-index: 99;
  transition: all .3s ease;
}

.five-col-theme .column .inner-box h4 {
  color: #919191;
  font-size: 20px;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
  font-style: italic;
  margin-bottom: 0px;
}

.five-col-theme .column .inner-box h3 {
  position: relative;
  font-size: 24px;
  color: #253d4a;
  font-weight: 700;
  margin-top: 60px;
}
