
/***

====================================================================
    Agent Section
====================================================================

***/

.agent-section {
  position: relative;
  background: #ffffff;
}

.agent-section .column {
  position: relative;
  padding: 90px 0px 0px;
}

.agent-section .agent-image {
  position: relative;
  max-width: 400px;
  margin: 0 auto;
}

.agent-section .agent-image img {
  display: inline-block;
  width: 100%;
}

.bordered-title {
  position: relative;
  border-left: 5px solid #4bc700;
  padding-left: 30px;
  margin-bottom: 30px;
}

.bordered-title h2 {
  position: relative;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.2em;
  margin-bottom: 5px;
}

.bordered-title p {
  position: relative;
  font-style: italic;
  color: #50575e;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2em;
}

.agent-section .agent-headers {
  position: relative;
  margin-bottom: 20px;
}

.agent-section h3 {
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 0px;
  color: #253d4a;
  font-weight: 700;
}

.agent-section h4 {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 0px;
  color: #253d4a;
  font-weight: 700;
}

.agent-section .designation {
  font-size: 14px;
  margin-bottom: 0px;
  color: #cccccc;
  font-style: italic;
  font-family: 'Bitter', serif;
}

.agent-section .desc-text {
  position: relative;
  margin-bottom: 30px;
}

.agent-section .info-box {
  position: relative;
  margin-bottom: 50px;
}

.agent-section .info-box li {
  position: relative;
  float: left;
  width: 50%;
  padding-right: 30px;
  margin-bottom: 20px;
  font-size: 12px;
  min-height: 40px;
  color: #353535;
}

.agent-section .info-box li a {
  color: #90a2a8;
}

.agent-section .info-box li a:hover {
  color: #4bc700;
}

.agent-section .info-box li.address,
.agent-section .info-box li.contact-info {
  padding-left: 45px;
}

.agent-section .info-box li .icon span:before {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 40px;
  font-size: 32px;
  line-height: 42px;
  color: #4bc700;
  font-style: normal;
}

.agent-section .info-box .social-links {
  position: relative;
  font-size: 13px;
  text-transform: uppercase;
}

.agent-section .info-box .social-links a {
  position: relative;
  display: inline-block;
  font-size: 13px;
  height: 40px;
  margin-right: 5px;
  text-align: center;
  line-height: 38px;
  width: 40px;
  color: #cdcdcd;
  border: 1px solid #cdcdcd;
  border-radius: 50%;
  transition: .5s ease;
}

.agent-section .info-box .social-links a:hover {
  background: #4bc700;
  border-color: #4bc700;
  transition: .5s ease;
  color: #fff;
}
