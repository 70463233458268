

/***

====================================================================
    google-map Section
====================================================================

***/

.map-section {
  position: relative;
}

.map-section .google-map {
  position: relative;
  width: 100%;
  margin-right: 20px;
  height: 500px;
  background: #d0d0d0;
}
