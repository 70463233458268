
/***

====================================================================
    Global Settings
====================================================================

 ***/

body {
  font-family: 'Open sans', sans-serif;
  font-size: 15px;
  color: #888888;
  line-height: 1.8em;
  font-weight: 400;
  background: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #4bc700;
}

a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  margin: 0px;
  background: none;
  line-height: 1.6em;
}

input,
button,
select,
textarea {
  font-family: 'Montserrat', sans-serif;
}

p {
  position: relative;
  font-size: 16px;
  line-height: 1.7em;
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  margin-left: 0;
}

.strike-through {
  text-decoration: line-through;
}

.auto-container {
  position: static;
  max-width: 1200px;
  padding: 0px 15px;
  margin: 0 auto;
}

.small-container {
  max-width: 680px;
  margin: 0 auto;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
