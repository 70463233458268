
/***

====================================================================
    Main Header style
====================================================================

***/

.main-header {
  position: relative;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  transition: all 500ms ease;
}

.sticky-header {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0 0;
  background: #ffffff;
  z-index: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: all 500ms ease;
}

.sticky-header .logo {
  padding: 6px 0 2px;
  img {
    max-height: 57px;
  }
}

.sticky-header.now-visible {
  z-index: 999;
  opacity: 1;
  visibility: visible;
  animation-name: fadeInDown;
  animation-duration: 500ms;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}


/*Header Style Two*/

.main-header .header-top {
  position: relative;
  background: #2c2c2c;
  color: #999999;
}

.main-header .header-top .top-right {
  position: relative;
  float: right;
}

.main-header .header-top .info {
  position: relative;
  float: left;
  padding: 8px 0;
}

.main-header .header-top .info li {
  position: relative;
  float: left;
  margin-right: 20px;
  line-height: 26px;
}

.main-header .header-top .info li .icon:before {
  position: relative;
  top: 1px;
  font-size: 14px;
  padding-right: 5px;
}

.main-header .header-top .info li a {
  color: #fff;
  display: block;
  font-size: 13px;
  transition: all 0.5s ease;
}

.main-header .header-top .info li a:hover {
  color: #999;
}

.main-header .header-top .social-links {
  position: relative;
  font-size: 13px;
  text-transform: uppercase;
  margin-left: 30px;
}

.main-header .header-top .social-links a {
  position: relative;
  display: inline-block;
  padding: 8px 19px;
  font-size: 13px;
  color: #fff;
  border-left: 1px solid #3d3d3d;
  transition: .5s ease;
}

.main-header .header-top .social-links a:hover {
  background: #4bc700;
  transition: .5s ease;
}

.main-header .header-top .social-links a:last-child {
  border-right: 1px solid #3d3d3d;
}

.main-header .header-top a:hover {
  color: #ffffff;
}

.main-header .header-upper {
  position: relative;
  padding: 5px 0 0;
  background: #ffffff;
  transition: all 500ms ease;
  z-index: 5;
}

.header-style-two .header-upper {
  position: absolute;
  left: 0;
  right: 0;
}

.transparent-bg.header-upper {
  background: none;
}

.main-header .header-upper .logo img {
  position: relative;
  display: inline-block;
  max-width: 100%;
}

.main-header .header-upper .upper-right {
  position: relative;
  padding: 0 0;
}

.main-header .info-box {
  position: relative;
  float: left;
  min-height: 40px;
  margin: 20px 0 10px 40px;
  padding-left: 35px;
}

.main-header .info-box .icon-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 40px;
  line-height: 40px;
  color: #4bc700;
  font-size: 24px;
}

.main-header .info-box .text {
  position: relative;
  line-height: 20px;
  font-size: 13px;
  color: #253d4a;
}

.main-header .info-box li a {
  color: #253d4a;
}

.main-header .header-lower {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  background: none;
  z-index: 5;
}

.main-header .header-lower .bg-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: none;
  overflow: hidden;
}

.main-header .header-lower .bg-layer:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  width: 50%;
  height: 100%;
  background: #4bc700;
}

.header {
  display: flex;
  > * {
    flex: 0 0 auto;
  }

  @media only screen and (min-width: 768px){
    flex-direction: column;
  }

  @media only screen and (min-width: 1151px){
    flex-direction: row;
  }

}

.main-header .header-upper .logo-outer {
  max-width: 132px;

  @media only screen and (min-width: 768px) {
    max-width: 190px;
    margin: 14px 0;
    text-align: center;
  }
  @media only screen and (min-width: 1151px){
    text-align: left;
    margin-right: auto;
  }
}
.main-header .nav-outer {
  background: none;
}

.menu-outer {
  margin-left: auto;
  @media only screen and (min-width: 768px) {
    margin-left: 0;
  }
  @media only screen and (min-width: 1151px){
    margin-left: auto;
    margin-top: 50px;
  }
}

.main-menu {
  @media only screen and (min-width: 768px){
    text-align: center;
  }
}

.main-menu .navbar-collapse {
  padding: 0;
  position: absolute;
  left: 0;
  width: 100%;
  @media only screen and (min-width: 768px) {
    position: static;
    left: auto;
    width: auto;
  }
}

.main-menu .navigation {
  position: relative;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  @media only screen and (min-width: 768px){
    display: inline-flex;
  }
}

.main-menu .navigation > li {
  position: relative;
  float: left;
  padding: 0;
}

.sticky-header .main-menu .navigation > li {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.main-menu .navigation > li > a {
  position: relative;
  display: block;
  padding: 15px 25px;
  font-size: 18px;
  color: #253d4a;
  line-height: 20px;
  margin-right: 1px;
  border-top: none;
  font-weight: 400;
  text-transform: capitalize;
  opacity: 1;
  transition: all 500ms ease;
}

.sticky-header .main-menu .navigation > li > a {
  padding: 21px 25px !important;
}

.main-menu .navigation > li.dropdown > a {
  padding-right: 30px;
}

.header-style-one .main-menu .navigation > li.dropdown > a,
.header-style-two .main-menu .navigation > li.dropdown > a {
  padding-right: 15px;
}

.sticky-header .main-menu .navigation > li > a {
  color: #181818;
  padding: 15px 25px 15px 10px;
  font-size: 14px;
}

.header-style-one .sticky-header .main-menu .navigation > li > a,
.header-style-two .sticky-header .main-menu .navigation > li > a {
  padding: 15px 25px 15px 10px;
  line-height: 30px;
}

.header-style-one .main-menu .navigation > li.dropdown > a:before,
.header-style-two .main-menu .navigation > li.dropdown > a:before {
  right: 0;
  line-height: 24px;
}

.header-style-one .sticky-header .main-menu .navigation > li.dropdown > a:before,
.header-style-two .sticky-header .main-menu .navigation > li.dropdown > a:before {
  right: 10px;
  line-height: 32px;
}

.main-menu .navigation > li:hover > a,
.main-menu .navigation > li.current > a,
.main-menu .navigation > li.current-menu-item > a {
  background: #4bc700;
  opacity: 1;
}

.header-style-one .main-menu .navigation > li:hover > a,
.header-style-one .main-menu .navigation > li.current > a,
.header-style-one .main-menu .navigation > li.current-menu-item > a {
  background: none;
  color: #4bc700;
  opacity: 1;
}

.header-style-two .main-menu .navigation > li:hover > a,
.header-style-two .main-menu .navigation > li.current > a,
.header-style-two .main-menu .navigation > li.current-menu-item > a {
  background: none;
  color: #4bc700;
  opacity: 1;
}

.sticky-header .main-menu .navigation > li:hover > a,
.sticky-header .main-menu .navigation > li.current > a,
.sticky-header .main-menu .navigation > li.current-menu-item > a {
  background: #4bc700;
  color: #ffffff !important;
  opacity: 1;
}

.main-menu .navigation > li:hover > a:after {
  opacity: 1;
}

.main-menu .navigation > li > ul {
  position: absolute;
  left: 0;
  top: 120%;
  width: 200px;
  padding: 0;
  z-index: 100;
  display: none;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-top: 3px solid #4bc700;
  transition: all 500ms ease;
}


/*.main-menu .navigation > li > ul:before{
    font-family: 'FontAwesome';
    content: "\f0d8";
    position:absolute;
    padding-left:25px;
    width:100%;
    top:-12px;
    left:0px;
    line-height:16px;
    font-size:20px;
    display:block;
    color:#d0d0d0;
    z-index:5;
}*/

.main-menu .navigation > li > ul.from-right {
  left: auto;
  right: 0;
}

.main-menu .navigation > li > ul.from-right:before {
  right: 0;
  text-align: right;
  padding-left: 0;
  padding-right: 25px;
}

.main-menu .navigation > li > ul > li {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #d0d0d0;
  background: #ffffff;
}

.main-menu .navigation > li > ul > li:last-child {
  border-bottom: none;
}

.main-menu .navigation > li > ul > li > a {
  position: relative;
  display: block;
  padding: 8px 15px;
  line-height: 24px;
  font-weight: 500;
  font-size: 13px;
  text-transform: capitalize;
  color: #253d4a;
  transition: all 500ms ease;
}

.main-menu .navigation > li > ul > li:hover > a {
  color: #4bc700;
}

.main-menu .navigation > li > ul > li.dropdown > a:after {
  font-family: 'FontAwesome';
  content: "\f105";
  position: absolute;
  right: 10px;
  top: 10px;
  width: 10px;
  height: 20px;
  display: block;
  color: #253d4a;
  line-height: 20px;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  z-index: 5;
}

.main-menu .navigation > li > ul > li.dropdown:hover > a:after {
  color: #4bc700;
}

.main-menu .navigation > li > ul > li > ul {
  position: absolute;
  left: 110%;
  top: 0;
  width: 200px;
  padding: 0;
  z-index: 100;
  display: none;
  background: #ffffff;
  border-top: 3px solid #4bc700;
}

.main-menu .navigation > li > ul > li > ul > li {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #d0d0d0;
  background: #ffffff;
}

.main-menu .navigation > li > ul > li > ul > li:last-child {
  border-bottom: none;
}

.main-menu .navigation > li > ul > li > ul > li > a {
  position: relative;
  display: block;
  padding: 8px 15px;
  line-height: 24px;
  font-weight: 500;
  font-size: 13px;
  text-transform: capitalize;
  color: #253d4a;
  transition: all 500ms ease;
}

.main-menu .navigation > li > ul > li > ul > li > a:hover {
  color: #4bc700;
}

.main-menu .navigation > li.dropdown:hover > ul {
  visibility: visible;
  opacity: 1;
  top: 100%;
}

.main-menu .navigation li > ul > li.dropdown:hover > ul {
  visibility: visible;
  opacity: 1;
  left: 100%;
  transition: all 500ms ease;
}

.main-menu .navbar-collapse > ul li.dropdown .dropdown-btn {
  position: absolute;
  right: 10px;
  top: 6px;
  width: 34px;
  height: 30px;
  border: 1px solid #ffffff;
  background: url(../images/icons/submenu-icon.png) center center no-repeat;
  background-size: 20px;
  cursor: pointer;
  z-index: 5;
  display: none;
}

.main-header .btn-outer {
  position: absolute;
  right: 0;
  top: 0;
  width: 200px;
  height: 100%;
}

.main-header .btn-outer .visit-btn {
  position: relative;
  display: block;
  line-height: 26px;
  text-align: center;
  padding: 17px 0;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  text-transform: uppercase;
  background: #4bc700;
  z-index: 1;
  transition: all 500ms ease;
}

.main-header.header-style-one .btn-outer .visit-btn,
.main-header.header-style-two .btn-outer .visit-btn {
  line-height: 30px;
  padding: 10px 0;
}

.main-header .btn-outer .visit-btn:hover {
  opacity: 0.80;
}

.main-header .get-btn:hover {
  background: #4bc700;
}

.main-header .get-btn .appt-btn:hover {
  color: #ffffff;
}

.social-links-one {
  position: relative;
}

.main-header .social-links-one {
  top: 7px;
}

.social-links-one a {
  position: relative;
  display: inline-block;
  text-align: center;
  margin-left: 4px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #928d8d;
  border-radius: 50%;
  background: #f4f4f4;
  transition: all 500ms ease;
}

.social-links-one a:hover {
  background: #4bc700;
  color: #ffffff;
}

.social-links-two a {
  position: relative;
  display: inline-block;
  text-align: center;
  margin-right: 5px;
  width: 40px;
  height: 40px;
  border: 1px solid #e0e0e0;
  line-height: 38px;
  font-size: 14px;
  color: #cccccc;
  border-radius: 50%;
  background: none;
  transition: all 500ms ease;
}

.social-links-two a:hover {
  background: #4bc700;
  border-color: #4bc700;
  color: #ffffff;
}

.social-links-three a {
  position: relative;
  display: inline-block;
  text-align: center;
  margin-right: 5px;
  width: 32px;
  height: 32px;
  border: 1px solid #f1f1f1;
  line-height: 30px;
  font-size: 12px;
  color: #aaaaaa;
  background: #f1f1f1;
  border-radius: 50%;
  transition: all 500ms ease;
}

.social-links-three a:hover {
  background: #4bc700;
  border-color: #4bc700;
  color: #ffffff;
}

