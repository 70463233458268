
/***

====================================================================
    Intro Section
====================================================================

***/

.intro-section {
  position: relative;
  padding: 30px 0px;
  background: #4bc700;
  color: #ffffff;
}

.intro-section img {
  height: 90px;
}

.intro-section.style-two {
  position: relative;
  background: #ffffff;
  color: #1a1d1f;
}

.intro-section .outer-box {
  position: relative;
  padding-left: 300px;
}

.intro-section .outer-box .anim-image {
  position: absolute;
  left: 0px;
  top: 0px;
}

.intro-section h2 {
  font-size: 24px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.intro-section p {
  margin-top: 15px;
  font-size: 14px;
  padding-left: 20px;
  border-left: 3px solid #ffffff;
}

.intro-section.style-two p {
  border-left-color: #4bc700;
}

.intro-section .theme-btn {
  margin-top: 18px;
  padding: 10px 25px;
  border: 1px solid #fff !important;
}

.intro-section .theme-btn:hover {
  background: #fff;
  color: #4bc700;
}

.intro-section.style-two .btn-style-two {
  border-color: #4bc700 !important;
  color: #4bc700 !important;
}

