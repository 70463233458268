
/***

====================================================================
    Main Slider style
====================================================================

***/

.main-slider {
  position: relative;
  width: 100%;
}

.main-slider .tp-caption {
  z-index: 5;
  white-space: normal;
}

.main-slider.style-one {
  margin-top: 0;
}

.main-slider .tp-banner-container {
  overflow: hidden !important;
}

.main-slider .bg-box .text {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 15px;
}

.main-slider .bg-box .text a {
  display: inline-block;
  margin-left: 10px;
  color: #4bc700;
  border-bottom: 1px dotted #4bc700 !important;
  line-height: 24px;
}

.main-slider .bg-box h1 {
  line-height: 1.2em;
  font-size: 48px;
  font-weight: 400;
  text-transform: uppercase;
}

.main-slider .bg-box h2 {
  line-height: 1.2em;
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 10px;
}

.main-slider .bg-box h3 {
  line-height: 1.2em;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 10px;
}

.main-slider .bg-box h4 {
  line-height: 1.2em;
  font-weight: 400;
  font-size: 20px;
}

.main-slider .bg-box h5 {
  line-height: 1.2em;
  font-weight: 500;
  font-size: 18px;
  color: #4bc700;
  margin-bottom: 10px;
}

.main-slider .bg-box {
  position: relative;
  padding: 50px 40px 40px;
  display: block;
  max-width: 650px;
  background: rgba(26, 29, 31, 0.80);
  color: #ffffff;
}

.main-slider .bg-box .inline-list {
  position: relative;
  margin-bottom: 10px !important;
}

.main-slider .bg-box .inline-list li {
  position: relative;
  display: inline-block;
  margin-right: 15px !important;
  line-height: 24px !important;
  text-transform: capitalize;
  font-size: 13px;
}

.main-slider .bg-box .inline-list li .icon {
  font-size: 14px;
  padding-right: 10px;
  display: inline-block;
}

.main-slider .tp-bullets.preview3 .bullet {
  border: none !important;
  margin: 0px 4px;
  width: 28px !important;
  height: 4px !important;
  background: rgba(255, 255, 255, 0.50) !important;
  display: inline-block;
}

.main-slider .tp-bullets.preview3 .bullet:hover,
.main-slider .tp-bullets.preview3 .bullet.selected {
  background: #4bc700 !important;
}

.main-slider .tparrows.preview3 {
  background: none !important;
  width: 70px;
}

.main-slider .tparrows.preview3::after {
  top: 20px;
  color: #ffffff;
  font-size: 18px;
  height: 52px;
  line-height: 50px;
  width: 52px;
  border: 1px solid #272828;
  background: #272828;
  border-radius: 50%;
}

.main-slider .tparrows.tp-leftarrow.preview3::after {
  content: '\f104';
  font-family: 'FontAwesome';
}

.main-slider .tparrows.tp-rightarrow.preview3::after {
  content: '\f105';
  font-family: 'FontAwesome';
}

.main-slider .tparrows.preview3.tp-leftarrow {
  left: 20px !important;
}

.main-slider .tparrows.preview3.tp-rightarrow {
  right: 0px !important;
}

.main-slider .tparrows.preview3:hover::after {
  border-color: #4bc700 !important;
  background: #4bc700;
}

.main-slider .tparrows.preview3 .tp-arr-iwrapper {
  visibility: hidden;
  opacity: 0;
}

.main-slider .tp-bannertimer {
  display: none !important;
}

.main-slider .outer-box {
  position: relative;
  max-width: 450px;
  background: #fff;
  color: #1c1c1c;
  padding: 18px 0px 0px;
}

.main-slider .outer-box .top-area {
  padding: 0px 45px 18px;
  margin-bottom: 12px;
  border-bottom: 1px solid#eee;
}

.main-slider .outer-box .bottom-area {
  padding: 0px 45px 45px;
}

.main-slider .outer-box {
  h2, .h2 {
    position: relative;
    font-family: 'Raleway', sans-serif;
    font-size: 32px;
    font-weight: 500;
    line-height: 44px;
    text-transform: capitalize;
    span {
      font-weight: 800;
    }
  }
}


.main-slider .outer-box .text {
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;

  p {
    font-size: 14px;
  }

}

.main-slider .outer-box .info-list {
  position: relative;
  border-top: 1px solid rgba(255, 255, 255, 0.30) !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.30) !important;
  margin-bottom: 9px !important;
}

.main-slider .outer-box .info-list li {
  position: relative;
  float: left;
  width: 33.333%;
  padding: 20px 20px !important;
  padding-left: 0px !important;
  font-size: 14px;
  text-align: center;
}

.main-slider .outer-box .info-list li:first-child {
  text-align: left;
}

.main-slider .outer-box .info-list li strong {
  display: block;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 2px;
}

.main-slider .outer-box .info-list li:after {
  content: '';
  position: absolute;
  right: -1px;
  border-right: 1px solid rgba(255, 255, 255, 0.30);
  top: 0%;
  height: 100%;
}

.main-slider .outer-box .info-list li:last-child:after {
  display: none;
}

.main-slider .outer-box .price {
  font-size: 46px;
  line-height: 30px;
  color: #4bc700;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
}

.main-slider .outer-box .btn-style-one {
  position: relative;
  font-family: 'Montserrat', sans-serif;
  padding: 22px 39px;
  width: 50%;
  height: 68px;
  border: 0;
  color: #fff;
  float: left;
}
.main-slider .outer-box .btn-style-one.bg-2:hover,
.main-slider .outer-box .btn-style-one.bg-1:hover {
  background: #4BC700;
  color: #fff !important;
}

.main-slider .outer-box .bg-1 {
  background: #313131;
}

.main-slider .outer-box .bg-2 {
  background: #242424;
  /*left: -3px;*/
}

.main-slider .outer-box .curve-right {
  position: absolute;
  top: 50px;
  right: -16px;
  margin-top: -15px;
  height: 30px;
  width: 16px;
  overflow: hidden;
}

.main-slider .outer-box .curve-right:after {
  position: absolute;
  content: '';
  top: 0;
  right: 7px;
  height: 30px;
  width: 30px;
  background: #fff;
  transform: rotate(-45deg);
}

.main-slider .outer-box .curve-left {
  position: absolute;
  top: 50px;
  left: -16px;
  margin-top: -15px;
  height: 30px;
  width: 16px;
  overflow: hidden;
}

.main-slider .outer-box .curve-left:after {
  position: absolute;
  content: '';
  top: 0;
  left: 7px;
  height: 30px;
  width: 30px;
  background: #fff;
  transform: rotate(-45deg);
}
