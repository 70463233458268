
.theme-btn {
  display: inline-block;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  font-family: 'Roboto', sans-serif;
}

.centered {
  text-align: center;
}

.btn-style-one {
  position: relative;
  padding: 12px 26px;
  line-height: 24px;
  text-transform: capitalize;
  background: #4bc700;
  color: #ffffff ;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  border: 2px solid #4bc700 ;
  transition: .5s ease;
}

.btn-style-one:hover {
  color: #4bc700 ;
  border-color: #4bc700 ;
  background: none;
  transition: .5s ease;
}

.btn-style-two {
  position: relative;
  padding: 12px 26px;
  line-height: 24px;
  text-transform: capitalize;
  background: none;
  color: #4bc700 ;
  font-size: 14px;
  font-weight: 500;
  border: 2px solid #4bc700 ;
  transition: .5s ease;
}

.btn-style-two:hover {
  color: #ffffff ;
  border-color: #4bc700 ;
  background: #4bc700;
  transition: .5s ease;
}

.btn-style-three {
  position: relative;
  padding: 12px 26px;
  line-height: 24px;
  text-transform: capitalize;
  background: #253d4a;
  color: #ffffff ;
  font-size: 14px;
  font-weight: 500;
  border: 2px solid #253d4a ;
  transition: .5s ease;
}

.btn-style-three:hover {
  color: #253d4a ;
  border-color: #253d4a ;
  background: none;
  transition: .5s ease;
}

.btn-style-four {
  position: relative;
  padding: 12px 26px;
  line-height: 24px;
  text-transform: capitalize;
  background: none;
  color: #253d4a ;
  font-size: 14px;
  font-weight: 500;
  border: 2px solid #253d4a ;
  transition: .5s ease;
}

.btn-style-four:hover {
  color: #ffffff ;
  border-color: #253d4a ;
  background: #253d4a;
  transition: .5s ease;
}

.btn-style-five {
  position: relative;
  padding: 10px 32px;
  line-height: 24px;
  text-transform: capitalize;
  background: none;
  color: #ffffff ;
  font-size: 14px;
  font-weight: 500;
  border: 2px solid #ffffff ;
  transition: .5s ease;
}

.btn-style-five:hover {
  color: #4bc700 ;
  border-color: #ffffff ;
  background: #ffffff;
  transition: .5s ease;
}

.btn-style-six {
  position: relative;
  padding: 12px 30px;
  line-height: 24px;
  text-transform: capitalize;
  background: none;
  color: #253d4a ;
  font-size: 14px;
  font-weight: 500;
  border: 2px solid #e0e0e0 ;
  border-radius: 4px;
  transition: .5s ease;
}

.btn-style-six:hover {
  color: #ffffff ;
  border-color: #253d4a ;
  background: #253d4a;
  transition: .5s ease;
}

.theme-btn .fa {
  font-size: 16px;
}

.theme-btn .icon-left {
  padding-right: 10px;
}

.theme-btn .icon-right {
  padding-left: 10px;
}

.theme_color {
  color: #4bc700;
}

.light-font {
  font-weight: 300;
}

.regular-font {
  font-weight: 400;
}

.semibold-font {
  font-weight: 600;
}

.bold-font {
  font-weight: 700;
}

.padd-top-10 {
  padding-top: 10px !important;
}

.padd-top-20 {
  padding-top: 20px !important;
}

.padd-top-30 {
  padding-top: 30px !important;
}

.padd-top-40 {
  padding-top: 40px !important;
}

.padd-top-50 {
  padding-top: 50px !important;
}

.padd-top-60 {
  padding-top: 60px !important;
}

.padd-top-70 {
  padding-top: 70px !important;
}

.padd-left-10 {
  padding-left: 10px !important;
}

.padd-left-20 {
  padding-left: 20px !important;
}

.padd-left-30 {
  padding-left: 30px !important;
}

.padd-left-40 {
  padding-left: 40px !important;
}

.padd-left-50 {
  padding-left: 50px !important;
}

.padd-left-60 {
  padding-left: 60px !important;
}

.padd-left-70 {
  padding-left: 70px !important;
}

.padd-bott-10 {
  padding-bottom: 10px !important;
}

.padd-bott-20 {
  padding-bottom: 20px !important;
}

.padd-bott-30 {
  padding-bottom: 30px !important;
}

.padd-bott-40 {
  padding-bottom: 40px !important;
}

.padd-bott-50 {
  padding-bottom: 50px !important;
}

.padd-bott-60 {
  padding-bottom: 60px !important;
}

.padd-bott-70 {
  padding-bottom: 70px !important;
}

.padd-bott-90 {
  padding-bottom: 90px !important;
}

.padd-bott-100 {
  padding-bottom: 100px !important;
}

.padd-right-10 {
  padding-right: 10px !important;
}

.padd-right-20 {
  padding-right: 20px !important;
}

.padd-right-30 {
  padding-right: 30px !important;
}

.padd-right-40 {
  padding-right: 40px !important;
}

.padd-right-50 {
  padding-right: 50px !important;
}

.padd-right-60 {
  padding-right: 60px !important;
}

.padd-right-70 {
  padding-right: 70px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-top-40 {
  margin-top: 40px !important;
}

.margin-top-50 {
  margin-top: 50px !important;
}

.margin-top-60 {
  margin-top: 60px !important;
}

.margin-top-70 {
  margin-top: 70px !important;
}

.margin-bott-10 {
  margin-bottom: 10px !important;
}

.margin-bott-20 {
  margin-bottom: 20px !important;
}

.margin-bott-30 {
  margin-bottom: 30px !important;
}

.margin-bott-40 {
  margin-bottom: 40px !important;
}

.margin-bott-50 {
  margin-bottom: 50px !important;
}

.margin-bott-60 {
  margin-bottom: 60px !important;
}

.margin-bott-70 {
  margin-bottom: 70px !important;
}

.no-padd-bottom {
  padding-bottom: 0px !important;
}

.no-padd-top {
  padding-top: 0px !important;
}

.no-margin-bottom {
  margin-bottom: 0px !important;
}

.no-margin-top {
  margin-bottom: 0px !important;
}

.no-padd-left {
  padding-left: 0px !important;
}

.no-padd-right {
  padding-right: 0px !important;
}

.bg-lightgrey {
  background-color: #f9f9f9 !important;
}

.no-bg {
  background: none !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}



.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #4BC700;
  /*background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../images/icons/preloader.GIF);*/
}


.sk-circle {
  margin: 22% auto;
  width: 60px;
  height: 60px;
  position: relative;
}
.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg); }
.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg); }
.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }
.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg); }
.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg); }
.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }
.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg); }
.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg); }
.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg); }
.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg); }
.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg); }
.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s; }
.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s; }
.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s; }
.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s; }
.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s; }
.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s; }
.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s; }
.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s; }
.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s; }
.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s; }
.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s; }

@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
}


