/***

====================================================================
    recent-news style
====================================================================

***/

.recent-news {
  position: relative;
  padding-top: 98px;
  padding-bottom: 14px;
  background: #f9f9f9;
}

.recent-news .single-column {
  background: #fff;
  margin-bottom: 100px;
}

.recent-news .section-title {
  padding-top: 33px;
  padding-bottom: 82px;
}

.recent-news .section-title h2 {
  font-size: 36px;
  color: #2b3033;
  margin-bottom: 30px;
}

.recent-news .section-title h2 a {
  color: #2b3033;
  transition: .5s ease;
}

.recent-news .section-title h2 a:hover {
  color: #08c1d7;
  transition: .5s ease;
}

.recent-news .section-title p {
  position: relative;
  font-size: 18px;
}

.recent-news .img-box {
  position: relative;
  overflow: hidden;
}

.recent-news .img-box img {
  position: relative;
  width: 100%;
  display: block;
  transition: .5s ease;
}

.recent-news .content {
  position: relative;
  padding: 45px;
  padding-top: 0;
}

.recent-news .content {
  h2, .h2 {
    position: relative;
    font-size: 18px;
    color: #2b3033;
    line-height: 30px;
    font-weight: 600;
    font-family: 'Raleway', sans-serif;
    margin-bottom: 15px;
    a {
      color: #2b3033;
      transition: .5s ease;
    }
    a:hover {
      color: #4bc700;
      transition: .5s ease;
    }
  }
}



.recent-news .content h3,
.recent-news .content .h3 {
  position: relative;
  font-size: 13px;
  color: #c7c7c7;
  margin-top: 35px;
  margin-bottom: 13px;
}

.recent-news .content p {
  position: relative;
  font-size: 15px;
  color: #a0a7ac;
  line-height: 26px;
  margin-bottom: 29px;
  max-width: 350px;
}

.recent-news .content p {
  color: #777c7d;
}

.recent-news .content .btn-style-default {
  position: relative;
  color: #4bc700;
  text-transform: uppercase;
}

.recent-news .content .btn-style-default:after {
  position: absolute;
  content: '';
  bottom: -8px;
  left: 0;
  height: 1px;
  width: 100%;
  background: #4bc700;
  transition: .5s ease;
}

.recent-news .single-column:hover .content .btn-style-default:after {
  width: 120%;
  transition: .5s ease;
}

