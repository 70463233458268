
/***

====================================================================
    Reset
====================================================================

 ***/

* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
}
