

/***

====================================================================
    Scroll To Top style
====================================================================

***/

.scroll-to-top {
  position: fixed;
  bottom: 15px;
  right: 15px;
  color: #ffffff;
  font-size: 12px;
  line-height: 32px;
  width: 34px;
  height: 34px;
  border: 1px solid #4bc700;
  background: #4bc700;
  text-align: center;
  z-index: 100;
  cursor: pointer;
  display: none;
  border-radius: 17px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.scroll-to-top:hover {
  background: #ffffff;
  color: #4bc700;
}

