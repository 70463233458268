
/*
* Layout Control styles
*/

body {transition: width .4s ease;}
body.boxed {
  width: 100%;
  max-width: 1300px;
  margin-left: auto;margin-right: auto;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  background: #fff;
}
body section {background: #fff;}