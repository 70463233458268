
/***

====================================================================
    Main Footer
====================================================================

***/

.main-footer {
  position: relative;
  background: #323232;
}

.main-footer .copy-right {
  position: relative;
}

.main-footer .copy-right {
  position: relative;
  line-height: 70px;
  font-size: 14px;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
}

.main-footer .copy-right span {
  color: #4bc700;
}

.main-footer .social-links a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  margin-right: 11px;
  padding: 0 3px;
  text-align: center;
  line-height: 70px;
  color: #7d7d7d;
  transition: .5s ease;
}

.main-footer .social-links a:hover {
  color: #4bc700;
  transition: .5s ease;
}
